<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row v-if="!isLoading" no-gutters class="mx-2">

      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            disabled
            icon
            class="btn-primary"
            color="primary"
            @click="setShowEditDialog(true)">
            <v-icon small>fas fa-pencil</v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <!-- Info -->
      <v-col cols="12">

        <v-row no-gutters>

          <!-- Plan -->
          <v-col cols="12">
            <h3>{{ $t('accounts.tabs.subscription.labels.plan')}}</h3>
            <span v-if="item.plan">{{ item.plan }}</span>
            <span v-else>{{ $t('common.misc.NA') }}</span>
          </v-col>

          <!-- Billing address -->
          <v-col cols="12">
            <h3>{{ $t('accounts.tabs.subscription.labels.billing_address')}}</h3>
            <Address
              :address="item.billing_address"
            />
          </v-col>

          <!-- Invoices -->
          <v-col cols="12">
            <h3>{{ $t('accounts.tabs.subscription.labels.invoices') }}</h3>
            <p>Available soon....</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Skeleton
      v-else
      type="table"
      :occurrence="1"
    />
  </v-card>
</template>

<script>
import vuetifyDataTable from "@/mixins/vuetifyDataTable";

export default {
  name: "AccountSubscriptionTab",

  components: {
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    Address: () => import("@/components/Common/Cards/Address")
  },
  mixins: [vuetifyDataTable],

  props: {
    account: {type: Object, default: () => null}
  },

  data() {
    return {
      isLoading: true,
      showEditDialog: false,
      item: null
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoading = true;

      this.$http
        .get(`/accounts/${this.account.id}/subscription`, {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res => {
          this.item = res.data;
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setShowEditDialog(val, needRefresh = false) {
      if (needRefresh)
        this.getData();
      this.showEditDialog = val;
    }
  }
}
</script>

<style scoped>

</style>
