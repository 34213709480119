import { render, staticRenderFns } from "./AccountSubscriptionTab.vue?vue&type=template&id=66c46b2c&scoped=true&"
import script from "./AccountSubscriptionTab.vue?vue&type=script&lang=js&"
export * from "./AccountSubscriptionTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c46b2c",
  null
  
)

export default component.exports